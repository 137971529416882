<template>
  <div :class="{'normal-box': props.size === 'normal', 'small-box': props.size === 'small'}">
    <el-switch :model-value="isDark" class="theme" @click="toggleTheme">
      <template #active-action>
        <div class="dark-box">
          <img src="https://static.ppkao.com/www/images/dark-mode.png" alt="暗夜模式"/>
        </div>
      </template>
      <template #inactive-action>
        <div class="light-box">
          <img src="https://static.ppkao.com/www/images/light-mode.png" alt="日间模式"/>
        </div>
      </template>
    </el-switch>
  </div>
</template>

<script setup>
const props = defineProps({
  size: {
    type: String,
    default: 'normal'
  }
})
const nuxtApp = useNuxtApp();
const isDark = computed(() => nuxtApp.$darkMode?.isDarkMode?.value);

// 主题模式切换
const toggleTheme = (event) => {
  nuxtApp.$darkMode.toggleTheme(event);
};

</script>

<style scoped lang="scss">

.normal-box {
  :deep(.is-checked) {
    .el-switch__action {
      left: calc(100% - 38px) !important;
    }
  }
}
.small-box {
  :deep(.el-switch__core) {
    border: 1px solid var(--border-2-color);
  }
  :deep(.is-checked) {
    .el-switch__action {
      left: calc(100% - 28px) !important;
    }
  }
  .theme {
    height: 32px !important;
  }
  :deep(.el-switch__core) {
    width: 60px !important;
    height: 30px !important;
    background-color: var(--border-color) !important;
  }
  :deep(.el-switch__action) {
    width: 24px !important;
    height: 24px !important;
    left: 0px;
  }

  .light-box {
    width: 24px !important;
    height: 24px !important;
  }
  .dark-box {
    width: 24px !important;
    height: 24px !important;
    img {
     width: 14px;
    }
  }
}
.theme {
  margin-right: 10px;
  height: 42px;
  :deep(.el-switch__core) {
    width: 86px;
    height: 42px;
    border-radius: 100px;
    background: var(--switch-on-color);
    --el-switch-off-color: var(--switch-on-color)
  }
  :deep(.el-switch__action) {
    width: 32px;
    height: 32px;
    left: 6px;
    background-color: var(--switch-on-color);
  }

  .light-box {
    @include circle(32px);
    box-shadow: 0 1px 11px 0 rgba(0,0,0,0.06);
    background-color: var(--card-bg-color);
    @include flex-center();
  }
  .dark-box {
    @include circle(32px);
    box-shadow: 0 1px 11px 0 rgba(0,0,0,0.06);
    background-color: var(--card-bg-color);
    @include flex-center();
  }
}

</style>
